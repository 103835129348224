export enum ProfileCardType {
    Personal = 'personal',
    Business = 'business'
}

export type ProfileCardLink = {
    enabled: boolean;
    url?: string | null;
    prefix?: string | null;
};

export type ProfileCardLinks = {
    phone?: ProfileCardLink | null;
    email?: ProfileCardLink | null;
    instagram?: ProfileCardLink | null;
    tiktok?: ProfileCardLink | null;
    twitter?: ProfileCardLink | null;

    facebook?: ProfileCardLink | null;
    snapchat?: ProfileCardLink | null;
    whatsapp?: ProfileCardLink | null;
    youtube?: ProfileCardLink | null;

    paypal?: ProfileCardLink | null;
    venmo?: ProfileCardLink | null;
    cashapp?: ProfileCardLink | null;
};

type ProfileCardCTA = {
    enabled?: boolean | null;
    url?: string | null;
    label?: string | null;
};

export type ProfileCardDataType = {
    slug: string;
    enabled?: boolean | null;
    title?: string | null;
    profession_title?: string | null;
    description?: string | null;
    links?: ProfileCardLinks | null;
    editable_header?: string | null;
    editable_text?: string | null;
    cta_1?: ProfileCardCTA | null;
    cta_2?: ProfileCardCTA | null;
    enable_booking?: boolean | null;
    backgrounds?: string[] | null;
    available_backgrounds: Array<{ filename: string; url: string }>;
};

export type ProfileCardPayload = Omit<ProfileCardDataType, 'available_backgrounds'>;
